<template>
    <v-container fluid>
        <v-row row wrap>
            <v-expansion-panels popout>
                <v-expansion-panel

                    v-for="(item,n) in products"
                    :key="`expansion-${n}`"
                >
                <v-expansion-panel-header>
                    <v-card flat >
                        <ProductHeader
                        :product="item"
                        :hideDateDetail="hideDateDetail"
                        :isDashboard="true"
                        item-key="productId"
                        v-bind:index="n"
                        />
                    </v-card>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                            <ProductSummary
                                :item="item" 
                                item-key="name"
                                @product-updated="productUpdated(item, $event)"
                            />
                </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapMutations} from 'vuex'
import ProductHeader from '@/components/product/ProductHeader'
import ProductSummary from '@/components/product/ProductSummary'
import moment from 'moment-timezone'
export default {
    data () {
        return {
            pagination: {'sortBy': 'casesRemaining', 'descending': true, 'rowsPerPage': 8},
            selected: [],
            headers: [
                { text: 'Name', align: 'left', sortable: false, value: 'product'},
                { text: 'Release Date', align: 'center', sortable: true, value: 'releaseDate' , class: "hidden-xs-only"},
                { text: 'Cases Ordered', align: 'center', sortable: true, value: 'casesOrdered' , class: "hidden-xs-only"},
                { text: 'Cases Owed', value: 'casesRemaining', sortable: true, align: 'right'},
            ],
        }
    },
    components: {
        ProductHeader,
        ProductSummary
    },
    props: {
        products: {type: Array, required: true},
        hideDateDetail: {type: Boolean, required: false},
    },
    computed: {
        ...mapGetters ([
            'loading'
        ]),
        pages () {
        if (this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
        ) return 0
        return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
      }
    },
    methods: {
        ...mapMutations ([
            'setLoading'
        ]),
        shortDate (dateStr) {
            return moment(dateStr, "YYYY-MM-DD @HH:mm").calendar()
        },
        lastDay (dateStr) {
            let diff = moment(dateStr, "YYYY-MM-DD @HH:mm").diff(moment(), "hours")
            return diff < 24
        },
        productUpdated (product, event) {
            //console.log("productUpdated item", item)
            // event is {field: value}
            // get key value pairs of the updated fields
            for (let key in event) {
                product[key] = event[key]
            }
          //  console.log("productUpdated product", product)
        },
    }
}
</script>
